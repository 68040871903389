<template>
  <div class="fill-height">
    <slot />
  </div>
</template>

<script>
    export default {
        name: "AdditionalInformationViewContent",
        props: {
          expand: {
            type: Boolean,
            default: false,
          },
        },
        watch: {
        }
    }
</script>
