<template>
  <div
    class="bottom-sheet__header"
    :class="{ expanded: expanded }"
    style="padding: .7rem 0 .6rem 0;"
  >
    <div class="d-flex">
      <div style="width: calc(100% - 50px); height: 100%;">
        <slot>
          No Header
        </slot>
      </div>
      <div style="width: 50px; padding-top: .45rem;">
        <v-btn
          v-if="!expanded"
          icon
          color="white"
          @click="onClickExpand"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          @click="onClickShrink"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
    .bottom-sheet__header {
        width: 100%;
        display: block;
        height: 4.375rem;
        background: #555555;
        color: white;
    }

    .bottom-sheet__header.expanded {
        background: #fff;
        color: black;
    }
</style>

<script>
    export default {
        name: "AdditionalInformationViewHeader",
        props: {
            expand: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                expanded: false,
            }
        },
        watch: {
          expand() {
            this.expanded = this.expand
          }
        },
        created() {
            this.expanded = this.expand;
        },
        methods: {
            /**
             * 확장버튼 클릭
             */
            onClickExpand() {
                this.expanded = true;
                this.backGuard.push(() => {
                    this.expanded = false;
                    this.$emit('update:expand', false);
                });
                this.$emit('update:expand', true);
            },

            /**
             * 축소버튼 클릭
             */
            onClickShrink() {
                this.expanded = false;
                this.$emit('update:expand', false);
                this.backGuard.pop();
            }
        },
    }
</script>
