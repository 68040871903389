<template>
  <div class="sheet">
    <div style="height: 4.375rem;">
      <slot
        name="header"
      >
        <div>no header</div>
      </slot>
    </div>
    <!--컨텐츠-->
    <!--    <v-expand-transition>-->
    <!--      <div-->
    <!--        v-show="expand"-->
    <!--        :style="contentHeight"-->
    <!--      >-->
    <div
      class="additional-information__content"
      :class="{'additional-information____hide': !expand, 'additional-information____show': expand}"
    >
      <slot
        name="content"
      >
        <div>no content</div>
      </slot>
    </div>
    <!--    </v-expand-transition>-->
  </div>
</template>

<script>
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";

    export default {
        name: "AdditionalInformationView",
        props: {
            expand: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                mount: false,
                sheet: false,
                expanded: false,
            };
        },
        computed: {
            additionalInformationWrapper() {
                if (!this.mount) return null;
                let hierarchyCount = 0;
                while (true) {
                    hierarchyCount++;
                    let expression = 'this';
                    for (let i = 0; i < hierarchyCount; i++) {
                        expression += '.$parent';
                    }
                    const vue = eval(expression);
                    // 더이상 Vue 객체가 없음.
                    if (vue === undefined) return null;
                    if (vue.isAdditionalInformationWrapper) return vue;
                }
            },

            wrapperHeight() {
                if (isNOU(this.additionalInformationWrapper)) return undefined;
                if (!this.additionalInformationWrapper) {
                    console.error("AdditionalInformationWrapper 컴포넌트가 없습니다.");
                    return undefined;
                }
                console.log(this.additionalInformationWrapper.$el.clientHeight);
                return this.additionalInformationWrapper.$el.clientHeight;
            },

            contentHeight() {
                if (isNOU(this.wrapperHeight)) return "";
                return 'height: calc(' + this.wrapperHeight + 'px - 4.375rem);';
            },
        },
        mounted() {
            this.mount = true;
        },
    }
</script>

<style scoped>
    /*.additional-information__content {*/
    /*  height: calc(100vh - 4.375rem - 56px);*/
    /*}*/

    .sheet {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
    }

    .additional-information__content {
      transition: height .35s;
    }

    .additional-information____hide {
        height: 0px;
    }

    .additional-information____show {
        height: calc(100vh - 4.375rem - 56px);
    }
</style>