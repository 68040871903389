<script>
    import BaseListener from '@/assets/plugins/mps-common/event/base-listener'

    export default {
        name: "OnEtcRequestSuccessed",
        extends: BaseListener,
        mounted() {
            this.registerListener(this.mpsEvents.ON_ETC_REQUEST_SUCCESSED, this.onEtcRequestSuccessed);
        },
        unmounted() {
            this.unregisterListener(this.mpsEvents.ON_ETC_REQUEST_SUCCESSED, this.onEtcRequestSuccessed);
        },
        methods: {
            onEtcRequestSuccessed() {
                alert('onEtcRequestSuccessed is needed to be implemented!');
            }
        },
    }
</script>
