import { render, staticRenderFns } from "./AdditionalInformationViewHeader.vue?vue&type=template&id=510f3080&scoped=true"
import script from "./AdditionalInformationViewHeader.vue?vue&type=script&lang=js"
export * from "./AdditionalInformationViewHeader.vue?vue&type=script&lang=js"
import style0 from "./AdditionalInformationViewHeader.vue?vue&type=style&index=0&id=510f3080&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510f3080",
  null
  
)

export default component.exports